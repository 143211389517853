@font-face {
  font-family: "Arvo";
  font-display: auto;
  src: local("Arvo"),
    url(https://fonts.gstatic.com/s/arvo/v9/rC7kKhY-eUDY-ucISTIf5PesZW2xOQ-xsNqO47m55DA.woff2)
      format("woff2");
}

.main {
  background-color: #e4e5e6 !important;
}

.required {
  color: red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.documentPdf {
  position: relative;
  margin-top:50%;
}

.modal-mc > .ant-modal-content > .ant-modal-body {
  padding: 0 !important;
}

.tabs-chat > .ant-tabs-bar {
  min-height: 80vh;
  max-width: 13em;
}

.tabs-chat .ant-tabs-nav-scroll {
  max-height: 80vh;
}

.ant-tabs-nav .ant-tabs-tab{
  white-space: normal;
}

.no-pad .ant-drawer-body {
  padding: 0;
}

.tabs-chat .ant-tabs-left-content {
  padding: 0;
  background-image: url('https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png');
  min-height: 91vh;
  max-height: 91vh;
  overflow: auto;
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
  text-align: left;
}

.pointer-a {
  cursor: pointer;
}

.list-room {
  padding: 1.2em;
  cursor: pointer;
  width:'100%'
}

.list-room:hover {
  background-color: lightgrey;
}

.chat-room .ant-drawer-body {
  background-image: url('https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png');
  min-height: 90vh;
  max-height: calc( 100% - 5em );
  overflow: auto;
  padding: 0;
  padding: 1.2em;
}

.chat-room .ant-drawer-body::-webkit-scrollbar {
  width: 0.7em;
}

/* .chat-room .ant-drawer-body::-webkit-scrollbar-track {
  background: #1e1e24;
} */

.chat-room .ant-drawer-body::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 5px;
}

#scroll-preserta::-webkit-scrollbar {
  width: 0.7em;
}

/* .chat-room .ant-drawer-body::-webkit-scrollbar-track {
  background: #1e1e24;
} */

#scroll-preserta::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 5px;
}

.chat-room .ant-drawer-header {
  padding:12px 18px;
}

.drawer-info .ant-drawer-body {
  max-height: calc( 100% - 4em );
  overflow: auto;
}

.ant-notification {
  z-index: 2000;
}

/* .ant-popover {
  z-index: 1503;
} */

/* .ant-modal-mask {
  z-index: 1503;
}

.ant-modal-wrap  {
  z-index: 1503;
} */